import React from 'react'

import Layout from '../../components/layout'

import { Link } from 'gatsby'

import resource1 from './../../images/resources/renault-alpine-a110.jpg'
import casestudy2 from './../../images/resources/coming-soon.png'
import casestudy3 from './../../images/resources/coming-soon.png'

export default function alpine() {
  return (
    <Layout>
      <div className="services">
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="columns twelve">
                <h1 className="center-text">Renault Alpine</h1>
                <p>Introduction to our story</p>
                <p>lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: '100px' }}>
          <div className="row" style={{ marginBottom: '20px' }}>
            <div className="four columns">
              <Link to="/resources/alpine/alpine-example">
                <div className="casestudyBox">
                  <img className="caseImage" src={resource1} />
                  <div className="overlay">
                    <div className="caseText">
                      <h4> Renault Alpine A110</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="four columns">
              <Link to="/case-studies/casestudy1">
                <div className="casestudyBox">
                  <img className="caseImage" src={casestudy2} />
                  <div className="overlay">
                    <div className="caseText">
                      <h4>Make and Model</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="four columns">
              <Link to="/case-studies/casestudy1">
                <div className="casestudyBox">
                  <img className="caseImage" src={casestudy3} />
                  <div className="overlay">
                    <div className="caseText">
                      <h4>Make and Model</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
